import React, { useState, useEffect } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { Field } from '../../components/Field'
import { HeaderPage } from '../../components/HeaderPage'
import { disabledStyle, enabledStyle } from '../../styles/global'
import { HTTP_CONTANTS } from '../../config/http-constants'
import swal from 'sweetalert'
import { requestHttp } from '../../config/http-server'
import { SelectField } from '../../components/SelectField'
import { ROLES } from '../../config/data-constants'

export const User = () => {

  const [modeEditForm, setModeEditForm] = useState(false)
  const [validForm, setValidForm] = useState(false)
  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState('')

  const history = useHistory()
  const { id } = useParams()

  const onSubmitHandler = (e) => {
    e.preventDefault()
    const data = buildData()
    if (modeEditForm) {
      requestUpdateUser(data)
    } else {
      requestCreateUser(data)
    }
  }

  useEffect(() => {
    const idUser = Number(id)
    if (Number.isInteger(Number(idUser))) { // edit
      setModeEditForm(true)
      requestGetUser(idUser)
    } // add
  }, [])

  useEffect(() => {
    const isValid = code && name && password
    setValidForm(isValid)

    return () => { }
  }, [code, name, password])

  const buildData = () => {
    return {
      role,
      code,
      name,
      email: email.trim(),
      password
    }
  }

  const requestCreateUser = async (user) => {
    try {
      const endpoint = HTTP_CONTANTS.createUser
      const data = await requestHttp('post', endpoint, user)
      swal('¡Bien!', 'Usuario agregado', 'success')
        .then(_ => {
          redirectToBack()
        })
    } catch (error) {
      swal("Error", "El usuario que intenta registrar ya está siendo usado, por favor intente con un usuario distinto", "warning")
    }
  }

  const requestUpdateUser = async (userData) => {
    try {
      const enduser = `${HTTP_CONTANTS.updateUser}/${id}`
      const data = await requestHttp('put', enduser, userData)
      swal('¡Bien!', 'Usuario actualizado', 'success')
        .then(_ => {
          redirectToBack()
        })
    } catch (error) {
      swal("Error", "El usuario que intenta registrar ya está siendo usado, por favor intente con un usuario distinto", "warning")
    }
  }

  const requestGetUser = async (id) => {
    try {
      const endpoint = `${HTTP_CONTANTS.getUser}/${id}`
      const data = await requestHttp('get', endpoint)
      const { user } = data
      setCode(user.code)
      setName(user.name)
      setEmail(user.email)
      setRole(user.role)
      setPassword('__wycom__')
    } catch (error) {
      swal("Error", "No tiene autorización para ver esta información", "warning")
        .then(_ => {
          redirectToBack()
        })
    }
  }

  const redirectToBack = () => {
    history.goBack()
  }

  return (
    <div className="row">
      <section className="col-md-10">
        <main>
          <section className="page-content">
            <div className="user-section">
              <form onSubmit={onSubmitHandler}>
                <section className="form-custom">
                  <h4>Información de usuario</h4>
                  <SelectField value={role} onChange={value => setRole(value)} itemList={ ROLES } icon="flag-sharp" label="Seleccione el rol del usuario" />
                  <Field value={name} onChange={text => setName(text)} placeholder="ej: John Marin" icon="person" label="Nombre del usuario" />
                  <Field value={code} onChange={text => setCode(text)} placeholder="ej: PS001" icon="business" label="Código" />
                  <br />
                  <h4 className="header-color-highlight">Credenciales </h4>
                  <Field value={email} onChange={text => setEmail(text)} placeholder="ej: john.martin@empresa.com" icon="person" avoidSpaces={true} label="Correo electrónico" />
                  {
                    !modeEditForm && <Field value={password} onChange={text => setPassword(text)} placeholder="ej: ********" type="password" icon="shield-checkmark" label="Contraseña" />
                  }
                  <br /><br />
                  <div className="buttons-section">
                    <div className="flex-divider"></div>
                    <div>
                      <button type="submit" disabled={!validForm} style={!validForm ? disabledStyle : enabledStyle} className="pure-button primary-btn">Guardar Ahora</button>
                    </div>
                  </div>
                </section>
              </form>
            </div>
          </section>
        </main>
      </section>
    </div>
  )
}
