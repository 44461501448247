import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { HeaderPage } from '../../components/HeaderPage'
import { Field } from '../../components/Field'
import Pagination from "react-js-pagination"
import { HTTP_CONTANTS } from '../../config/http-constants'
import { requestHttp, requestHttpFile } from '../../config/http-server'
import { Unauthorized } from '../../hooks/CheckAuthorization'
import { Dropdown, Modal } from 'react-bootstrap'
import FileDownload from 'js-file-download'
import { disabledStyle, enabledStyle } from '../../styles/global'
import { getOrderStatusById } from '../../config/data-constants'


const initialPagination = {
  totalRows: 0
}

export const Orders = () => {

  const [showModalExportable, setModalExportable] = useState(false)
  const [idReportExportable, setIdReportExportable] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [orders, setOrders] = useState([])
  const [pagination, setPagination] = useState(initialPagination)
  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')
  const [pointId, setPointId] = useState(-1)
  const [validForm, setValidForm] = useState(false)

  const modalHideExportablehandler = () => setModalExportable(false);
  const modalExportableStandardHandler = () => loadModalExportable(2)

  const loadModalExportable = (reportId) => {
    setIdReportExportable(reportId)
    setModalExportable(true)
  }

  useEffect(() => {
    getOrders()

    return () => { }
  }, [activePage])

  const getOrders = async () => {
    try {
      const endpoint = HTTP_CONTANTS.getAllOrders
      const params = {
        page: activePage
      }
      const data = await requestHttp('get', endpoint, {}, params)
      const { list } = data
      setOrders(list)
      const pagination = {
        totalPages: data.totalPages,
        currentPage: data.currentPage,
        totalRows: data.totalRows,
        rowsPerPage: data.rowsPerPage
      }
      setPagination(pagination)
    } catch (err) {
      if (err.response.status === 401) {
        Unauthorized()
      } else {
        console.error('error 500')
      }
    }
  }

  const onSubmitReportHandler = (e) => {
    e.preventDefault()
    modalHideExportablehandler()
    exportableCustomHandler()
  }

  const exportableCustomHandler = async () => {
    try {
      const endpoint = HTTP_CONTANTS.exportableStandard
      const params = {
        from_date: dateFrom,
        to_date: dateTo
      }
      const response = await requestHttpFile('get', endpoint, {}, params)
      FileDownload(response.data, 'orders.txt')
    } catch (err) {
      if (err.response.status === 401) {
        Unauthorized()
      } else {
        console.error('error 500')
      }
    }
  }

  useEffect(() => {
    const isValid = dateFrom && dateTo
    setValidForm(isValid)

    return () => { }
  }, [dateFrom, dateTo])

  const exportableModalComponent = () => (
    <Modal show={showModalExportable} onHide={modalHideExportablehandler} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Exportar Datos</Modal.Title>
      </Modal.Header>
      <form onSubmit={onSubmitReportHandler}>
        <Modal.Body>
          <section className="form-custom">
            <Field value={dateFrom} onChange={text => setDateFrom(text)} type="date" icon="calendar" label="Desde" />
            <Field value={dateTo} onChange={text => setDateTo(text)} type="date" icon="calendar" label="Hasta" />
          </section>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button type="submit" disabled={!validForm} style={!validForm ? disabledStyle : enabledStyle} className="pure-button primary-btn">Exportar ahora</button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )

  const renderStatusOrder = (statusId) => {
    const statusValue = getOrderStatusById(statusId)
    return (
      <div className="badget-status-order" style={{backgroundColor: statusValue.color}}>
        { statusValue.value }
      </div>
    )
  }

  const TableContent = () => (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>Id único</th>
            <th>Cliente</th>
            <th>Sub total (COP)</th>
            <th>Total (COP)</th>
            <th>Dirección</th>
            <th>Descripción</th>
            <th>Fecha</th>
            <th>Estado</th>
            <th>Ver detalles</th>
          </tr>
        </thead>
        <tbody>
          {
            orders.map((item, key) => (
              <tr key={key} className={key % 2 === 0 ? 'pure-table-odd' : 'pure-table-pair'}>
                <td><strong>{item.id}</strong></td>
                <td>{item.customer ? item.customer.name : ''}</td>
                <td>{item.subtotal}</td>
                <td>{item.total}</td>
                <td>{item.address}</td>
                <td>{item.description}</td>
                <td>{item.order_date}</td>
                <td>{ renderStatusOrder(item.status) }</td>
                <td>
                  <Link to={`/home/order-detail/${item.id}`}>
                    Ver detalle
                  </Link>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )

  return (
    <>
      <main>
        <section className="page-content">
          <HeaderPage title="Pedidos realizados" icon="bonfire-sharp" />
          <section className="visit-list-container">
            <div className="buttons-table">
              <div className="flex-divider"></div>
              <Dropdown>
                <Dropdown.Toggle className="dropdown-btn" variant="info" id="dropdown-basic">
                  Exportar pedidos
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={modalExportableStandardHandler}>Formato para Siigo</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {
              <TableContent />
            }
            <div className="pagination-table">
              <div className="flex-divider"></div>
              <Pagination
                activePage={activePage}
                itemsCountPerPage={pagination.rowsPerPage}
                totalItemsCount={pagination.totalRows}
                pageRangeDisplayed={5}
                onChange={page => setActivePage(page)}
              />
            </div>
          </section>
        </section>
        {exportableModalComponent()}
      </main>
    </>
  )
}
