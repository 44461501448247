import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import UploadService from './FileUploadService'
import { disabledStyle, enabledStyle } from '../../styles/global'

export const UploadDataModal = ({ title = 'Importar datos', isShow = false, endpoint='/', onClose }) => {

  const [selectedFiles, setSelectedFiles] = useState(undefined)
  const [currentFile, setCurrentFile] = useState(undefined)
  const [message, setMessage] = useState('')
  const [progress, setProgress] = useState(0)
  const [fileInfos, setFileInfos] = useState([])
  const [errors, setErrors] = useState([])

  const onSubmitImportHander = (e) => {
    e.preventDefault()
    let currentFile = selectedFiles[0]

    setProgress(0);
    setCurrentFile(currentFile)

    UploadService.upload(currentFile, endpoint, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then((response) => {
        setMessage('Archivo cargado correctamente')
        setErrors(response.data.errors)
      })
      /*.then((files) => {
        setFileInfos(files.data)
      })*/
      .catch((err) => {
        console.log('err', err)
        setProgress(0)
        setMessage("El archivo no pudo ser cargado")
        setCurrentFile(undefined)
      });

    setSelectedFiles(undefined)
  }

  const selectFile = (event) => {
    setSelectedFiles(event.target.files)
  }

  return (
    <Modal show={isShow} onHide={onClose} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <form onSubmit={onSubmitImportHander}>
        <Modal.Body>
          {currentFile && (
            <div className="progress">
              <div
                className="progress-bar progress-bar-info progress-bar-striped"
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progress + "%" }}
              >
                {progress}%
              </div>
            </div>
          )}

          <section className="form-custom">
            <input type="file" onChange={selectFile} />
          </section>
          <br />
          <div>
            <p>{message}</p>
            {
              errors.length > 0
              && <h4>Ocurrio un error procesando el archivo</h4>
            }
            <ul>
            {
              errors.map((el, key) => <li key={key}>{ el }</li> )
            }
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button type="submit" disabled={!selectedFiles} style={!selectedFiles ? disabledStyle : enabledStyle} className="pure-button primary-btn">Subir</button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  )

}
