import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { MenuContext } from '../../../contexts/MenuContext'
import Logo from './../../../static/logo.png'
import { Unauthorized } from '../../../hooks/CheckAuthorization'

const showMenuPanel = {
  display: 'block'
}

const hideMenuPanel = {
  display: 'none'
}

export const SideMenu = () => {

  const { menuPanel, setMenuPanel } = useContext(MenuContext)

  const menuPanelHandler = () => {
    setMenuPanel(!menuPanel)
  }

  const closeSessionHandler = (e) => {
    e.preventDefault()
    Unauthorized ()
  }

  return (
    <section className="side-menu">
      <section className="topbar">
        <div className="topbar-menu">
          <img alt="logo" src={Logo} width="130px" />
          <div className="flex-divider"></div>
          <div className="hamburger-icon-menu" onClick={() => menuPanelHandler()}>
            <ion-icon name="menu-sharp"></ion-icon>
          </div>
        </div>
      </section>
      <section style={menuPanel ? showMenuPanel : hideMenuPanel} className="menu">
        <section className="menu-container-content">
          <div className="logo-sidemenu-container">
            <div className="icon-app-menu">
              <img alt="logo" src={Logo} width="160px" />
            </div>
          </div>
          <ul className="menu-list">
            <Link to="/home">
              <li>
                <ion-icon name="home"></ion-icon>
                <p>Indicadores</p>
              </li>
            </Link>
            <Link to="/home/orders">
              <li>
                <ion-icon name="folder-open-sharp"></ion-icon>
                <p>Pedidos</p>
              </li>
            </Link>
            <Link to="/home/products">
              <li>
                <ion-icon name="folder-open-sharp"></ion-icon>
                <p>Productos</p>
              </li>
            </Link>
            <Link to="/home/customers">
              <li>
                <ion-icon name="person-sharp"></ion-icon>
                <p>Clientes</p>
              </li>
            </Link>
            <Link to="/home/users">
              <li>
                <ion-icon name="person-sharp"></ion-icon>
                <p>Usuarios</p>
              </li>
            </Link>
            <a onClick={ closeSessionHandler } href="#">
              <li>
                <ion-icon name="log-out"></ion-icon>
                <p>Cerrar sesión</p>
              </li>
            </a>
          </ul>
        </section>
      </section>
    </section>
  )
}
