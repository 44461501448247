import React, { useState, useEffect } from 'react'
import { HeaderPage } from '../../components/HeaderPage'
import Pagination from "react-js-pagination"
import { HTTP_CONTANTS } from '../../config/http-constants'
import { requestHttp, requestHttpFile } from '../../config/http-server'
import { Unauthorized } from '../../hooks/CheckAuthorization'
import { Dropdown, Modal } from 'react-bootstrap'
import { UploadDataModal } from '../Modals/UploadDataModal'
import { getStockById } from '../../config/data-constants'
import { Field } from '../../components/Field'


const initialPagination = {
  totalRows: 0
}

export const Products = () => {

  const [activePage, setActivePage] = useState(1)
  const [products, setProducts] = useState([])
  const [pagination, setPagination] = useState(initialPagination)
  const [validForm, setValidForm] = useState(false)
  //const [showModalImportProviders, setShowModalImportProviders] = useState(false)
  const [showModalImportProducts, setShowModalImportProducts] = useState(false)
  const [showModalImportImagesProducts, setShowModalImportImagesProducts] = useState(false)
  const [search, setSearch] = useState('')

  useEffect(() => {
    getProducts()

    return () => { }
  }, [activePage])

  const getProducts = async () => {
    try {
      const endpoint = HTTP_CONTANTS.getProducts
      const params = {
        page: activePage,
        search
      }
      const data = await requestHttp('get', endpoint, {}, params)
      const { list } = data
      setProducts(list)
      const pagination = {
        totalPages: data.totalPages,
        currentPage: data.currentPage,
        totalRows: data.totalRows,
        rowsPerPage: data.rowsPerPage
      }
      setPagination(pagination)
    } catch (err) {
      if (err.response.status === 401) {
        Unauthorized()
      } else {
        console.error('error 500')
      }
    }
  }

  const onSubmitSearch = (e) => {
    e.preventDefault()
    getProducts()
  }

  const TableContent = () => (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>Sku</th>
            <th>Nombre</th>
            <th>Proveedor</th>
            <th>Stock</th>
            <th>Paquete</th>
            <th>Impuesto</th>
            <th>Impoconsumo</th>
          </tr>
        </thead>
        <tbody>
          {
            products.map((item, key) => (
              <tr key={key} className={key % 2 === 0 ? 'pure-table-odd' : 'pure-table-pair'}>
                <td><strong>{item.sku}</strong></td>
                <td>{item.name}</td>
                <td>{item.provider ? item.provider.name : ''}</td>
                <td>{ item.stock } </td>
                <td>{item.packaging} u.</td>
                <td>{item.tax}</td>
                <td>{item.extra_price}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )

  const onCloseModalUploadProducts = () => {
    setActivePage(1)
    getProducts()
    setShowModalImportProducts(false)
  }

  const onCloseModalUploadImagesProducts = () => {
    setActivePage(1)
    getProducts()
    setShowModalImportImagesProducts(false)
  }

  return (
    <>
      <main>
        <section className="page-content">
          <HeaderPage title="Catálogo de Productos" icon="bonfire-sharp" />
          <section className="visit-list-container">
            <div className="buttons-table">
              <div className="flex-divider"></div>
              <Dropdown>
                <Dropdown.Toggle className="dropdown-btn" variant="info" id="dropdown-basic">
                  Importar Productos
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setShowModalImportProducts(true)}>Importar Datos de Productos</Dropdown.Item>
                  <Dropdown.Item onClick={() => setShowModalImportImagesProducts(true)}>Importar Fotos de productos</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="search-table">
              <form onSubmit={onSubmitSearch}>
                <Field value={ search } onChange={ text => setSearch(text) } placeholder="buscar..." icon="search" label="" />
              </form>
            </div>
            {
              <TableContent />
            }
            <div className="pagination-table">
              <div className="flex-divider"></div>
              <Pagination
                activePage={activePage}
                itemsCountPerPage={pagination.rowsPerPage}
                totalItemsCount={pagination.totalRows}
                pageRangeDisplayed={5}
                onChange={page => setActivePage(page)}
              />
            </div>
          </section>
        </section>
        {/*<UploadDataModal onClose={() => setShowModalImportProviders(false)} title="Importar Proveedores" isShow={showModalImportProviders} endpoint="/import/providers" />*/}
        <UploadDataModal onClose={onCloseModalUploadProducts} title="Importar Productos" isShow={showModalImportProducts} endpoint="/import/products" />
        <UploadDataModal onClose={onCloseModalUploadImagesProducts} title="Importar Imagenes de Productos" isShow={showModalImportImagesProducts} endpoint="/images/catalog" />
      </main>
    </>
  )
}
