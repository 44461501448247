import React, { useState, useEffect } from 'react'

const uncheckedColor = { color: '#cccccc' }
const checkedColor = { color: '#2FC4B2' }

export const SelectField = ({label = '', value= -1, onChange = () => {}, icon, itemList = [] }) => {

  const [itemSelected, setItemSelected] = useState(value)

  const handlerChange = (e) => {
    let value = e.target.value
    setItemSelected(value)
    onChange(value)
  }

  return (
    <div className="field-form">
      <div className="icon">
        <ion-icon name={ icon }></ion-icon>
      </div>
      <div className="field">
        <label>{ label }</label>
        <select value={ itemSelected } onChange={ handlerChange }>
          {
            itemList.map( (item, key)  => (
              <option key={ key } value={ item.id }>{ item.value }</option>
            ))
          }
        </select>
      </div>
      <div className="checked">
        <ion-icon style={ itemSelected  ? checkedColor: uncheckedColor } name="checkmark-circle"></ion-icon>
      </div>
    </div>
  )
}
