import http from './../../config/http-common';

const upload = (file, endpoint, onUploadProgress) => {
  let formData = new FormData();

  formData.append("file", file);

  const url = `${process.env.REACT_APP_API_URL}${endpoint}`
  const bearerToken = sessionStorage.getItem('__RabbitCookie__')
  return http.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      'Authorization': `Bearer ${bearerToken}`
    },
    onUploadProgress,
  })
}

export default {
  upload
};
