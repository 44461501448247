import React, { useEffect } from 'react'
import Logo from './../../static/logo.png'

export const Splash = ({history}) => {

  useEffect(() => {
    // validate token
    setTimeout(() => {
      history.push('/login')
    }, 1000)
  }, [history])

  return (
    <div className="splash-background">
      <div>
        <img width="260px" src={Logo} alt="logo" />
        <br /><br />
        <p>Procesando...</p>
      </div>
    </div>
  )
}
