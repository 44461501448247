import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { Home } from './Home'
import { Login } from './Login'
import { Signup } from './Signup'
import { Splash } from './Splash'
import { Disclaimer } from './Disclaimer'
import { Blocked } from './Blocked'
import ScrollToTop from '../hooks/ScrollToTop'

export const Routes = () => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route exact path='/' component={Splash} />
          <Route path='/home' component={Home} />
          <Route path='/login' component={Login} />
          <Route path='/signup' component={Signup} />
          <Route path='/disclaimer' component={Disclaimer} />
          <Route path='/blocked' component={Blocked} />
          <Route path='/trial-expired' component={Blocked} />
        </Switch>
      </ScrollToTop>
    </Router>
  )
}
