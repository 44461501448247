export const HTTP_CONTANTS = {
  login: '/publish/login',
  validate: '/publish/validate',
  visitors: '/guests',
  users: '/users',
  getUser: '/users', // id
  createUser: '/users',
  updateUser: '/users', // id
  changePasswordUser: '/users', // id
  getAllOrders: '/order/all',
  getOrderDetail: '/order/detail', //id
  updateOrderStatus: '/order/updateStatus', //id
  updateQuantityItem: '/order/updateQuantityItem',
  getProducts: '/product/all',
  getCustomers: '/customer/all',
  getCustomer: '/customer/one', // id
  createCustomer: '/customer',
  updateCustomer: '/customer/update', //id
  exportableStandard: '/export/siigo',
  getIndicators: '/dashboard/indicators',
  getReport: '/dashboard/report'
}
