import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Field } from '../../components/Field'
import { HeaderPage } from '../../components/HeaderPage'
import { disabledStyle, enabledStyle } from '../../styles/global'
import { HTTP_CONTANTS } from '../../config/http-constants'
import swal from 'sweetalert'
import { requestHttp } from '../../config/http-server'

export const UserPassword = () => {

  const [password, setPassword] = useState('')
  const [validForm, setValidForm] = useState(false)

  const history = useHistory()
  let { id } = useParams()

  const onSubmitHandler = (e) => {
    e.preventDefault()
    const data = buildData()
    requestPatchUser(data)
  }

  useEffect(() => {
    const idPoint = Number(id)
    if (Number.isInteger(Number(idPoint))) { // edit
      requestGetUser(idPoint)
    } // add
  }, [])

  useEffect(() => {
    const isValid = password
    setValidForm(isValid)

    return () => { }
  }, [password])

  const buildData = () => {
    return {
      password
    }
  }

  const requestPatchUser = async (point) => {
    try {
      const endpoint = `${HTTP_CONTANTS.changePasswordUser}/${id}`
      const data = await requestHttp('patch', endpoint, point)
      swal('¡Bien!', 'Contraseña actualizada', 'success')
        .then(_ => {
          redirectToBack()
        })
    } catch (error) {
      swal("Error", "Ocurrio un error realizando el proceso de cambio de contraseña", "error")
    }
  }

  const requestGetUser = async (id) => {
    try {
      console.log('requestGetPoint')
      const endpoint = `${HTTP_CONTANTS.getUser}/${id}`
      const data = await requestHttp('get', endpoint)
    } catch (error) {
      swal("Error", "No tiene autorización para ver este usuario", "warning")
        .then(_ => {
          redirectToBack()
        })
    }
  }

  const redirectToBack = () => {
    history.goBack()
  }

  return (
    <div className="row">
      <section className="col-md-10">
        <main>
          <section className="page-content">
            <HeaderPage title="Cambio de contraseña" icon="bonfire-sharp" />
            <div className="point-section">
              <form onSubmit={onSubmitHandler}>
                <section className="form-custom">
                  <h4>Nueva contraseña</h4>
                  <Field value={password} onChange={text => setPassword(text)} placeholder="ej: ********" type="password" icon="shield-checkmark" label="Contraseña" />
                  <br /><br />
                  <div className="buttons-section">
                    <div className="flex-divider"></div>
                    <div>
                      <button type="submit" disabled={!validForm} style={!validForm ? disabledStyle : enabledStyle} className="pure-button primary-btn">Guardar Ahora</button>
                    </div>
                  </div>
                </section>
              </form>
            </div>
          </section>
        </main>
      </section>
    </div>
  )
}
