import React, { useEffect, useContext, useState } from 'react'
import {
  Switch,
  Route,
  Link,
  useHistory
} from 'react-router-dom'
import { AccountContext } from '../../contexts/AccountContext'
import { HTTP_CONTANTS } from '../../config/http-constants'
import { requestHttp } from '../../config/http-server'
import { Unauthorized } from '../../hooks/CheckAuthorization'

import { SideMenu } from './components/SideMenu'
import { Orders } from '../Orders'
import { UserList } from '../UserList'
import { User } from '../User'
import { UserPassword } from '../UserPassword'
import { Subscripction } from '../Subscription'
import { Dashboard } from '../Dashboard'
import { OrderDetail } from '../OrderDetail'
import { Products } from '../Products'
import { Customers } from '../Customers'
import { Customer } from '../Customer'

const LayoutPage = ({ children, hoursTrial = 0, showTrial = false }) => (
  <>
    {
      showTrial
      && <div className="trial-panel-alert">
        Te restan <strong>{hoursTrial} horas </strong> para finalizar tu prueba gratuita
          <Link to="/home/subscription">
          Activar mi suscripción ahora
          </Link>
      </div>
    }
    <div className="layout-page">
      <nav>
        <SideMenu />
      </nav>
      {children}
    </div>
  </>
)

export const Home = () => {

  const [hoursTrial, setHoursTrial] = useState(0)
  const [showTrial, setShowTrial] = useState(false)
  const { setAccount } = useContext(AccountContext)

  const history = useHistory()

  useEffect(() => {
    validateAuth()
    // validateSubscription()
    return () => { }
  }, [])

  const validateAuth = async () => {
    try {
      const endpoint = HTTP_CONTANTS.validate
      const auth = await requestHttp('post', endpoint)
      const { user } = auth
      setAccount(user)
    } catch (err) {
      if (err.response.status === 401) {
        Unauthorized()
      } else {
        console.error('error 500', err)
      }
    }
  }

  /*const validateSubscription = async () => {
    try {
      const endpoint = HTTP_CONTANTS.getSubscription
      const data = await requestHttp('get', endpoint)
      const { subscription, account } = data
      if (subscription) {
        if (subscription.status === 2) {
          history.push('/blocked')
        }
      } else {
        const hours = checkTrialHours(account.created_at)
        if (hours < 0) {
          history.push('/trial-expired')
        } else {
          setShowTrial(true)
          setHoursTrial(hours)
        }
      }
    } catch (err) {
      console.error('error 500', err)
    }
  }

  const checkTrialHours = (createdAt) => {
    const currentDate = new Date()
    const dateInMillisecons = currentDate.setHours(currentDate.getHours() - 72) // 3 days
    const date3DaysAgo = new Date(dateInMillisecons)
    const accountCreatedAt = new Date(createdAt)
    return diffHours(accountCreatedAt, date3DaysAgo)
  }

  const diffHours = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000
    diff /= (60 * 60)
    return Math.round(diff)
  }*/

  return (
    <LayoutPage hoursTrial={hoursTrial} showTrial={showTrial}>
      <Switch>
        <Route exact path='/home' component={Dashboard} />
        <Route path='/home/orders' component={Orders} />
        <Route path='/home/order-detail/:id' component={OrderDetail} />
        <Route path='/home/users' component={UserList} />
        <Route path='/home/products' component={Products} />
        <Route path='/home/customers' component={Customers} />
        <Route path='/home/customer/:id' component={Customer} />
        <Route path='/home/user/:id' component={User} />
        <Route path='/home/user-password/:id' component={UserPassword} />
        <Route path='/home/subscription' component={Subscripction} />
      </Switch>
    </LayoutPage>
  )
}
