import React, { createContext, useState, useEffect } from 'react'

const initialState = false

export const MenuContext = createContext(initialState)

export const MenuContextStore = ({ children }) => {

  const [menuPanel, setMenuPanel] = useState(initialState)

  useEffect(() => {

    const handlerResize = () => {
      const showState = window.innerWidth > 768
      setMenuPanel(showState)
    }

    window.addEventListener('resize', handlerResize)
    handlerResize()

    return () => window.removeEventListener('resize', handlerResize)

  }, [])

  return (
    <MenuContext.Provider value={{ menuPanel, setMenuPanel }}>
      {children}
    </MenuContext.Provider>
  )
}
