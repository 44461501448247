import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import BgImage from './../../static/bg-home-signup.png'
import { Field } from '../../components/Field'
import Logo from './../../static/clippus_c.png'
import { HTTP_CONTANTS } from '../../config/http-constants'
import swal from 'sweetalert'
import { disabledStyle, enabledStyle } from '../../styles/global'
import { requestHttp } from '../../config/http-server'
import { Authorized } from '../../hooks/CheckAuthorization'

export const Signup = () => {

  const [validForm, setValidForm] = useState(false)
  const [accountName, setAccountName] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [phone, setPhone] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const history = useHistory()

  const onSubmitHandler = (e) => {
    e.preventDefault()
    const data = buildData()
    requestLogin(data)
  }

  useEffect(() => {
    const isValid = accountName && address && city && name && phone && email && password
    setValidForm(isValid)

    return () => { }
  }, [accountName, address, city, name, phone, email, password])

  const buildData = () => {
    return {
      account_name: accountName,
      address,
      city,
      phone,
      name,
      email: email.trim(),
      password
    }
  }

  const requestLogin = async (credentials) => {
    try {
      const endpoint = HTTP_CONTANTS.register
      const data = await requestHttp('post', endpoint, credentials)
      Authorized(data.access_token)
      swal('Bienvenido', 'Ahora haces parte de Clippus', 'success')
        .then(_ => {
          redirectToHome()
        })
    } catch (error) {
      swal("Error", "El correo que intenta registrar ya está siendo usado, por favor intente con un correo electrónico distinto", "warning")
    }
  }

  const redirectToHome = () => {
    history.push('/disclaimer')
  }

  return (
    <section className="container">
      <div className="row">
        <section className="col-md-6 d-none d-sm-block">
          <div className="logo">
            <img alt="logo" src={Logo} width="240px" />
          </div>
          <div className="main-image">
            <img alt="bg-login" src={BgImage} width="100%" />
          </div>
        </section>
        <section className="col-md-6">
          <section className="login-section">
            <div className="logo-mobile">
              <img alt="logo" src={Logo} width="240px" />
            </div>
            <h1>Crear una cuenta</h1>
            <form onSubmit={onSubmitHandler}>
              <section className="form-custom">
                <Field value={accountName} onChange={text => setAccountName(text)} placeholder="ej: restaurante johns" icon="business" label="Nombre de tu negocio" />
                <div className="row">
                  <div className="col-md-6">
                    <Field value={address} onChange={text => setAddress(text)} placeholder="ej: calle 150 # 17" icon="map" label="Dirección Principal" />
                  </div>
                  <div className="col-md-6">
                    <Field value={city} onChange={text => setCity(text)} placeholder="ej: Medellín" icon="map" label="Ciudad o Municipio" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                  <Field value={name} onChange={text => setName(text)} placeholder="ej: John Rodríguez" icon="person" label="Ingresa tu nombre" />
                  </div>
                  <div className="col-md-6">
                  <Field value={phone} onChange={text => setPhone(text)} placeholder="ej: 300553411" icon="person" label="Ingresa tu teléfono" />
                  </div>
                </div>
                <Field value={email} onChange={text => setEmail(text)} placeholder="ej: john@example.com" icon="mail" label="Ingresa tu correo electrónico" />
                <Field value={password} onChange={text => setPassword(text)} placeholder="Contraseña secreta" type="password" icon="shield-checkmark" label="Ingresa tu contraseña" />
                <br />
                <p>Al crear una cuenta en Clippus aceptas nuestros <a target="_blank" href="https://clippus.info/terminos.html">términos y condiciones</a> y nuestra <a target="_blank" href="https://clippus.info/politica-tratamiento-datos.html">política de protección de datos pesonales</a></p>
                <div className="buttons-section">
                  <p className="help-text">¿Ya tienes una cuenta? <Link className="primary-link" to="/login">Iniciar sesión</Link></p>
                  <div>
                    <button disabled={!validForm} style={!validForm ? disabledStyle : enabledStyle} type="submit" className="pure-button primary-btn">Acceder ahora</button>
                  </div>
                </div>
              </section>
            </form>
          </section>
        </section>
      </div>
    </section>
  )
}
