import React, { useEffect, useState } from 'react'
import { HeaderPage } from '../../components/HeaderPage'
import './styles.css'
import { requestHttp } from '../../config/http-server'
import { HTTP_CONTANTS } from '../../config/http-constants'

const defaultIndicators = {
  orders: 0,
  customers: 0,
  products: 0,
  users: 0
}

export const Dashboard = () => {

  const [indicators, setIndicators] = useState(defaultIndicators)

  useEffect(() => {
    getIndicators()
    return () => {}
  }, [])

  const getIndicators = async () => {
    try {
      const params = { }
      const endpoint = HTTP_CONTANTS.getIndicators
      const data = await requestHttp('get', endpoint, {}, params)
      const indicatorsObject = {
        orders: data.orders,
        customers: data.customers,
        products: data.products,
        users: data.users
      }
      setIndicators(indicatorsObject)
    } catch (err) {
      console.error('Err', err)
    }
  }

  return (
    <>
      <main>
        <section className="page-content">
          <HeaderPage title="Indicadores" icon="bonfire-sharp" />
        </section>
        <div className="container">
          <section>
            <div className="row">

              <div className="col-md-3">
                <div className="card-counter info">
                  <ion-icon name="basket"></ion-icon>
                  <span className="count-numbers">{indicators.orders}</span>
                  <span className="count-name">Ordenes</span>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card-counter primary">
                  <ion-icon name="business"></ion-icon>
                  <span className="count-numbers">{indicators.customers}</span>
                  <span className="count-name">Comercios</span>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card-counter danger">
                  <ion-icon name="people"></ion-icon>
                  <span className="count-numbers">{indicators.users}</span>
                  <span className="count-name">Usuarios</span>
                </div>
              </div>

              <div className="col-md-3">
                <div className="card-counter success">
                <ion-icon name="folder"></ion-icon>
                  <span className="count-numbers">{indicators.products}</span>
                  <span className="count-name">Productos</span>
                </div>
              </div>
            </div>
          </section>
          <hr />
          <br />
        </div>
      </main>
    </>
  )
}
