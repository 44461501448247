import React, { useState, useEffect } from "react";
import { UseFocus } from "../hooks/UseFocus";

const uncheckedColor = { color: "#cccccc" };
const checkedColor = { color: "#2FC4B2" };

export const Field = ({
  label = "",
  value = "",
  maxLength = 80,
  onChange = () => {},
  icon,
  placeholder = "",
  avoidSpaces = false,
  isTextArea = false,
  type = "text",
  width = '100px'
}) => {
  const [inputRef, setInputFocus] = UseFocus();
  const [input, setInput] = useState(value);

  const handlerInput = (e) => {
    let value = e.target.value;
    let text = value;
    if (avoidSpaces) {
      text = normalizeText(text);
      text = text.replace(" ", "").trim();
      text = text.replace("`", "").trim();
      text = text.replace("´", "").trim();
      text = text.replace("'", "").trim();
      text = text.toLowerCase();
    }
    setInput(text);
    onChange(text);
  };

  const normalizeText = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  useEffect(() => {
    setInput(value);

    return () => {};
  }, [value]);

  return (
    <div onClick={() => setInputFocus()} className="field-form">
      {
        icon
        && <div className="icon">
          <ion-icon name={icon}></ion-icon>
        </div>
      }
      <div className="field" style={{ width: width }}>
        <label>{label}</label>
        {isTextArea ? (
          <textarea
            maxLength={400}
            value={input}
            onChange={handlerInput}
            placeholder={placeholder}
            ref={inputRef}
            rows="3"
          />
        ) : (
          <input
            maxLength={maxLength}
            value={input}
            onChange={handlerInput}
            placeholder={placeholder}
            ref={inputRef}
            type={type}
          />
        )}
      </div>
      <div className="checked">
        <ion-icon
          style={input ? checkedColor : uncheckedColor}
          name="checkmark-circle"
        ></ion-icon>
      </div>
    </div>
  );
};
