import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Field } from '../../components/Field'
import { disabledStyle, enabledStyle } from '../../styles/global'
import { HTTP_CONTANTS } from '../../config/http-constants'
import swal from 'sweetalert'
import { requestHttp } from '../../config/http-server'

export const Customer = () => {

  const [modeEditForm, setModeEditForm] = useState(false)
  const [validForm, setValidForm] = useState(false)
  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [branchOffice, setBranchOffice] = useState('')
  const [city, setCity] = useState('')
  const [zone, setZone] = useState('')
  const [notes, setNotes] = useState('')
  const [email, setEmail] = useState('')
  const [nameUser, setNameUser] = useState('')
  const [password, setPassword] = useState('')

  const history = useHistory()
  const { id } = useParams()

  const onSubmitHandler = (e) => {
    e.preventDefault()
    const data = buildData()
    if (modeEditForm) {
      requestUpdateCustomer(data)
    } else {
      requestCreateCustomer(data)
    }
  }

  useEffect(() => {
    const idCustomer = Number(id)
    if (Number.isInteger(Number(idCustomer))) { // edit
      setModeEditForm(true)
      requestGetCustomer(idCustomer)
    } // add
  }, [])

  useEffect(() => {
    const isValid = code && name && password && email && nameUser
    setValidForm(isValid)

    return () => { }
  }, [code, name, password, email, nameUser])

  const buildData = () => {
    return {
      code,
      name,
      phone,
      address,
      branchOffice,
      city,
      zone,
      notes,
      email: email.trim(),
      nameUser,
      password
    }
  }

  const requestCreateCustomer = async (customer) => {
    try {
      const endpoint = HTTP_CONTANTS.createCustomer
      const data = await requestHttp('post', endpoint, customer)
      swal('¡Bien!', 'Cliente agregado', 'success')
        .then(_ => {
          redirectToBack()
        })
    } catch (error) {
      swal("Error", "El cliente que intenta registrar ya está siendo usado, por favor intente con datos diferentes", "warning")
    }
  }

  const requestUpdateCustomer = async (customerData) => {
    try {
      const enduser = `${HTTP_CONTANTS.updateCustomer}/${id}`
      const data = await requestHttp('put', enduser, customerData)
      swal('¡Bien!', 'Cliente actualizado', 'success')
        .then(_ => {
          redirectToBack()
        })
    } catch (error) {
      swal("Error", "El cliente que intenta registrar ya está siendo usado, por favor intente con datos distintos", "warning")
    }
  }

  const requestGetCustomer = async (id) => {
    try {
      const endpoint = `${HTTP_CONTANTS.getCustomer}/${id}`
      const data = await requestHttp('get', endpoint)
      const { customer } = data
      setCode(customer.unique_id)
      setName(customer.name)
      setPhone(customer.phone)
      setAddress(customer.address)
      setBranchOffice(String(customer.branch_office))
      setCity(String(customer.city))
      setZone(String(customer.zone))
      setNotes(customer.notes)

      setEmail(String(customer.user.email))
      setNameUser(customer.user.name)
      setPassword('__wycom__')
    } catch (error) {
      swal("Error", "No tiene autorización para ver esta información", "warning")
        .then(_ => {
          redirectToBack()
        })
    }
  }

  const redirectToBack = () => {
    history.goBack()
  }

  return (
    <div className="row">
      <section className="col-md-10">
        <main>
          <section className="page-content">
            <div className="user-section">
              <form onSubmit={onSubmitHandler}>
                <section className="form-custom">
                  <h4>Información del Cliente</h4>
                  <Field value={code} onChange={text => setCode(text)} placeholder="ej: 100100000" icon="business" label="Nit" />
                  <Field value={name} onChange={text => setName(text)} placeholder="ej: Comercio A" icon="person" label="Nombre del cliente" />
                  <Field value={phone} onChange={text => setPhone(text)} placeholder="ej: 3333333" icon="person" label="Teléfono" />
                  <Field value={address} onChange={text => setAddress(text)} placeholder="ej: calle" icon="person" label="Dirección" />
                  <Field value={branchOffice} onChange={text => setBranchOffice(text)} placeholder="ej: 1" icon="person" label="Código de Sucursal" />
                  <Field value={city} onChange={text => setCity(text)} placeholder="ej: 1" icon="person" label="Código de Ciudad" />
                  <Field value={zone} onChange={text => setZone(text)} placeholder="ej: 1" icon="person" label="Código de Zona" />
                  <Field value={notes} onChange={text => setNotes(text)} placeholder="ej: entrega los días..." icon="note" label="Notas para el cliente" isTextArea={true} />

                  <br />
                  <h4 className="header-color-highlight">Credenciales de acceso</h4>
                  <Field value={nameUser} onChange={text => setNameUser(text)} placeholder="ej: Juan" icon="person" avoidSpaces={true} label="Nombre de usuario" />
                  <Field value={email} onChange={text => setEmail(text)} placeholder="ej: john.martin@empresa.com" icon="person" avoidSpaces={true} label="Correo electrónico" />
                  {
                    !modeEditForm && <Field value={password} onChange={text => setPassword(text)} placeholder="ej: ********" type="password" icon="shield-checkmark" label="Contraseña" />
                  }
                  <br /><br />
                  <div className="buttons-section">
                    <div className="flex-divider"></div>
                    <div>
                      <button type="submit" disabled={!validForm} style={!validForm ? disabledStyle : enabledStyle} className="pure-button primary-btn">Guardar Ahora</button>
                    </div>
                  </div>
                </section>
              </form>
            </div>
          </section>
        </main>
      </section>
    </div>
  )
}
