import axios from 'axios'

const headersConfig = (bearerToken) => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${bearerToken}`
  }
  return defaultHeaders
}

const callHttp = async (options) => {
  try {
    const response = await axios(options);
    return response
  } catch (err) {
    throw err
  }
}

export const requestHttp = async (method = 'post', endpoint, body = {}, params = {}) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}${endpoint}`
    const bearerToken = sessionStorage.getItem('__RabbitCookie__')
    const options = {
      method: method,
      url: url,
      responseType: 'json',
      headers: headersConfig(bearerToken),
      data: body,
      params: params
    }
    const response = await callHttp(options)
    const { data } = response
    return data
  } catch (err) {
    throw err
  }
}

export const requestHttpFile = async (method = 'post', endpoint, body = {}, params = {}) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}${endpoint}`
    const bearerToken = sessionStorage.getItem('__RabbitCookie__')
    const options = {
      method: method,
      url: url,
      headers: headersConfig(bearerToken),
      data: body,
      params: params,
      responseType: 'arraybuffer'
    }
    const response = await callHttp(options)
    return response
  } catch (err) {
    throw err
  }
}
