import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Field } from '../../components/Field'
import { HeaderPage } from '../../components/HeaderPage'
import { disabledStyle, enabledStyle } from '../../styles/global'
import { HTTP_CONTANTS } from '../../config/http-constants'
import swal from 'sweetalert'
import { requestHttp } from '../../config/http-server'

export const Subscripction = () => {

  const [loading, setLoading] = useState(true)
  const [validForm, setValidForm] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const [legalName, setLegalName] = useState('')
  const [identification, setIdentification] = useState('')
  const [billingAddress, setBillingAddress] = useState('')
  const [billingPhone, setBillingPhone] = useState('')
  const [billingEmail, setBillingEmail] = useState('')
  const [privacyPolicy, setPrivacyPolicy] = useState('')
  const [status, setStatus] = useState(0)

  const history = useHistory()

  const onSubmitHandler = (e) => {
    e.preventDefault()
    const data = buildData()
    requestUpdateSubscription(data)
  }

  useEffect(() => {
    requestGetSubscription ()

    return () => { }
  }, [])

  useEffect(() => {
    const isValid = companyName && legalName && identification && billingAddress && billingAddress
      && billingPhone && billingEmail && privacyPolicy
    setValidForm(isValid)

    return () => { }
  }, [companyName, legalName, identification, billingAddress, billingAddress, billingEmail, billingPhone, privacyPolicy])

  const buildData = () => {
    return {
      company_name: companyName.trim(),
      legal_name: legalName.trim(),
      identification: identification.trim(),
      billing_email: billingEmail,
      billing_address: billingAddress,
      billing_phone: billingPhone,
      privacy_policy: privacyPolicy.trim(),
    }
  }

  const requestUpdateSubscription = async (subscriptionData) => {
    try {
      const endpoint = HTTP_CONTANTS.updateSubscription
      const data = await requestHttp('put', endpoint, subscriptionData)
      swal('¡Genial!', 'Suscripción actualizada', 'success')
        .then(_ => {
          window.location.href = '/home/subscription';
        })
    } catch (error) {
      swal("Error", "Ocurrio un error actualizando tu suscripción", "error")
    }
  }

  const requestGetSubscription = async () => {
    try {
      const endpoint = HTTP_CONTANTS.getSubscription
      const data = await requestHttp('get', endpoint)
      const { subscription } = data
      if (subscription) {
        setCompanyName(subscription.company_name)
        setLegalName(subscription.legal_name)
        setIdentification(subscription.identification)
        setBillingAddress(subscription.billing_address)
        setBillingPhone(subscription.billing_phone)
        setBillingEmail(subscription.billing_email)
        setPrivacyPolicy(subscription.privacy_policy)
        setStatus(subscription.status)
      }
      setLoading(false)
    } catch (error) {
      swal("Error", "No tiene autorización para ver esta información", "warning")
        .then(_ => {
          redirectToBack()
        })
    }
  }

  const redirectToBack = () => {
    history.goBack()
  }

  return (
    <div className="row">
      <section className="col-md-10">
        <main>
          <section className="page-content">
            <HeaderPage title="Información de mi suscripción" icon="bonfire-sharp" />
            <div className="subscription-section">
              {
                status === 1
                && <div className="full-notification ntf-green">
                  <ion-icon name="checkmark-circle"></ion-icon>
                    Tu suscripción de Clippus se encuentra activa
                    </div>
              }
              { !loading &&
                <form onSubmit={onSubmitHandler}>
                  <section className="form-custom">
                    <h4>Información de la empresa</h4>
                    <Field value={companyName} onChange={text => setCompanyName(text)} placeholder="ej: Comercio la estación" icon="business" label="Nombre de la empresa" />
                    <Field value={legalName} onChange={text => setLegalName(text)} placeholder="ej: Martin Rodriguez" icon="person" label="Representante legal o responsable de la cuenta" />
                    <Field value={identification} onChange={text => setIdentification(text)} placeholder="ej: 103800000" icon="document-text" label="Identificación tributaria (Nit)" />
                    <br />
                    <h4>Información legal</h4>
                    <Field value={privacyPolicy} onChange={text => setPrivacyPolicy(text)} maxLength={ 155 } placeholder="ej: https://miempresa.com/politica-proteccion-datos/" icon="document-attach" label="Enlace a documento de protección de datos o 'Habeas data' de tu empresa" />
                    <br />
                    <h4>Información de facturación</h4>
                    <Field value={billingAddress} onChange={text => setBillingAddress(text)} placeholder="ej: calle 106..." icon="map" label="Dirección física de facturación" />
                    <Field value={billingEmail} onChange={text => setBillingEmail(text)} placeholder="ej: facturación@miempresa.com" icon="mail" label="Dirección de correo electrónico de facturación" />
                    <Field value={billingPhone} onChange={text => setBillingPhone(text)} placeholder="ej: 30255000" icon="call" label="Teléfono de contacto de facturación" />
                    <br />
                    <p><strong>¡Considera que!</strong> Completando este formulario  estás aceptando las condiciones de Clippus y el cobro del servico mes vencido, durante el tiempo que uses la aplicación.</p>
                    <div className="buttons-section">
                      <div className="flex-divider"></div>
                      <div>
                        <button type="submit" disabled={!validForm} style={!validForm ? disabledStyle : enabledStyle} className="pure-button primary-btn">Guardar Ahora</button>
                      </div>
                    </div>
                  </section>
                </form>
              }
            </div>
          </section>
        </main>
      </section>
    </div>
  )

}
