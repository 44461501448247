
export const ROLES = [
  {
    id: 1,
    value: 'Administrador'
  },
  {
    id: 2,
    value: 'Vendedor'
  }
]

export const getRoleById= (id) => {
  const role = ROLES.find(x => x.id == id)
  return role != undefined ? role.value : ''
}

export const ORDER_STATUS = [
  {
    id: 1,
    color: '#ffc107',
    value: 'En proceso'
  },
  {
    id: 2,
    color: '#007bff',
    value: 'Realizado'
  },
  {
    id: 3,
    color: '#28a745',
    value: 'Confirmado'
  },
  {
    id: 4,
    color: '#212529',
    value: 'Cancelado'
  }
]

export const getOrderStatusById= (id) => {
  const found = ORDER_STATUS.find(x => x.id == id)
  return found != undefined ? found : {}
}

const BALE_TYPE = [
  {
    id: 1,
    name: 'Unidad'
  },
  {
    id: 2,
    name: 'Caja'
  }
]

export const getBaleTypeById= (id) => {
  const found = BALE_TYPE.find(x => x.id == id)
  return found != undefined ? found.name : ''
}
