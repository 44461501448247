import React, { useContext, useState, useEffect } from 'react'
import { AccountContext } from './../contexts/AccountContext'

export const HeaderPage = ({icon, title}) => {

  const { account } = useContext(AccountContext)

  return (
    <section className="header-page">
      <div className="icon-header">
        <ion-icon name={icon}></ion-icon>
      </div>
      <div className="content-header">
        <h4>{ title }</h4>
      </div>
    </section>
  )
}
