import React, { useState, useEffect } from 'react'
import { HeaderPage } from '../../components/HeaderPage'
import Pagination from "react-js-pagination"
import { HTTP_CONTANTS } from '../../config/http-constants'
import { requestHttp, requestHttpFile } from '../../config/http-server'
import { Unauthorized } from '../../hooks/CheckAuthorization'
import { Dropdown } from 'react-bootstrap'
import { UploadDataModal } from '../Modals/UploadDataModal'
import { Link } from 'react-router-dom'
import { Field } from '../../components/Field'


const initialPagination = {
  totalRows: 0
}

export const Customers = () => {

  const [activePage, setActivePage] = useState(1)
  const [customers, setCustomers] = useState([])
  const [pagination, setPagination] = useState(initialPagination)
  const [search, setSearch] = useState('')
  const [showModalImportCustomer, setShowModalImportCustomer] = useState(false)

  useEffect(() => {
    getCustomers()

    return () => { }
  }, [activePage])

  const getCustomers = async () => {
    try {
      const endpoint = HTTP_CONTANTS.getCustomers
      const params = {
        page: activePage,
        search
      }
      const data = await requestHttp('get', endpoint, {}, params)
      const { list } = data
      setCustomers(list)
      const pagination = {
        totalPages: data.totalPages,
        currentPage: data.currentPage,
        totalRows: data.totalRows,
        rowsPerPage: data.rowsPerPage
      }
      setPagination(pagination)
    } catch (err) {
      if (err.response.status === 401) {
        Unauthorized()
      } else {
        console.error('error 500')
      }
    }
  }

  const onSubmitSearch = (e) => {
    e.preventDefault()
    getCustomers()
  }

  const TableContent = () => (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>Nit</th>
            <th>Nombre Comercio</th>
            <th>Nombre Usuario</th>
            <th>Correo electrónico</th>
            <th>Teléfono</th>
            <th>Dirección</th>
            <th>Cod. Ciudad</th>
            <th>Cod. Zona</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            customers.map((item, key) => (
              <tr key={key} className={key % 2 === 0 ? 'pure-table-odd' : 'pure-table-pair'}>
                <td><strong>{item.unique_id}</strong></td>
                <td>{item.name}</td>
                <td>{item.user ? item.user.name : ''}</td>
                <td>{item.user ? item.user.email : ''}</td>
                <td>{item.phone}</td>
                <td>{item.address}</td>
                <td>{item.city}</td>
                <td>{item.zone}</td>
                <td>
                  <Link className="primary-link" to={`/home/user-password/${item.user.id}`}>
                    Cambiar contraseña
                  </Link>
                </td>
                <td>
                  <Link className="primary-link" to={`/home/customer/${item.id}`}>
                    Editar
                  </Link>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  )

  return (
    <>
      <main>
        <section className="page-content">
          <HeaderPage title="Clientes" icon="bonfire-sharp" />
          <section className="visit-list-container">
            <div className="buttons-table">
              <div className="flex-divider"></div>
              <Dropdown>
                <Dropdown.Toggle className="dropdown-btn" variant="info" id="dropdown-basic">
                  Importar datos
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setShowModalImportCustomer(true)}>Importar Clientes</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="search-table">
              <form onSubmit={onSubmitSearch}>
                <Field value={ search } onChange={ text => setSearch(text) } placeholder="buscar..." icon="search" label="" />
              </form>
            </div>
            {
              <TableContent />
            }
            <div className="pagination-table">
              <div className="flex-divider"></div>
              <Pagination
                activePage={activePage}
                itemsCountPerPage={pagination.rowsPerPage}
                totalItemsCount={pagination.totalRows}
                pageRangeDisplayed={5}
                onChange={page => setActivePage(page)}
              />
            </div>
          </section>
        </section>
        <UploadDataModal onClose={() => setShowModalImportCustomer(false)} title="Importar clientes" isShow={showModalImportCustomer} endpoint="/import/customers" />
      </main>
    </>
  )
}
