import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { HeaderPage } from '../../components/HeaderPage'
import { HTTP_CONTANTS } from '../../config/http-constants'
import { requestHttp } from '../../config/http-server'
import swal from 'sweetalert'
import { getBaleTypeById } from '../../config/data-constants'
import { SelectField } from '../../components/SelectField'
import {ORDER_STATUS} from '../../config/data-constants'
import { Field } from '../../components/Field'

export const OrderDetail = () => {

  const { id } = useParams()
  const history = useHistory()
  const [items, setItems] = useState([])
  const [orderDetail, setOrderDetail] = useState({status: -1})
  const [modeEditQuantity, setModeEditQuantity] = useState(false)
  const [idEditQuantity, setIdEditQuantity] = useState(-1)
  const [newEditQuantity, setNewEditQuantity] = useState(0)

  const onSubmitHandler = (e) => {
    e.preventDefault()
    requestUpdateOrderStatus()
  }

  const requestUpdateOrderStatus = async () => {
    try {
      const endpoint = `${HTTP_CONTANTS.updateOrderStatus}/${id}`
      const bodyData = {
        status: orderDetail.status
      }
      const data = await requestHttp('post', endpoint, bodyData)
      swal('Hecho!', 'Cambio de estado de la orden', 'success')
        .then(_ => {
          //redirectToBack()
        })
    } catch (error) {
      swal("Error", "Ocurrio un error actualizando el estado de la orden", "warning")
    }
  }

  useEffect(() => {
    requestOrderDetail()
  }, [])

  const onModeEditQuantity = (id, quantity) => {
    setModeEditQuantity(true)
    setIdEditQuantity(id)
    setNewEditQuantity(quantity)
  }

  const onChangeStatus = (status) => {
    setOrderDetail(prevState => {
      return{
        ...prevState,
        status: status
      }
    })
  }

  const onSaveEditQuantity = () => {
    requestChangeQuantityItem()
  }

  const onCancelEditQuantity = () => {
    setIdEditQuantity(-1)
    setModeEditQuantity(false)
    setNewEditQuantity(0)
  }

  const onChangeQuantityItem = (value) => {
    setNewEditQuantity(value)
  }

  const requestOrderDetail = async () => {
    try {
      const endpoint = `${HTTP_CONTANTS.getOrderDetail}/${id}`
      const data = await requestHttp('get', endpoint)
      const { orderItems } = data
      const { order } = data
      setItems(orderItems)
      setOrderDetail(order)
    } catch (error) {
      swal("Error", "No tiene autorización para ver esta información", "warning")
        .then(_ => {
          redirectToBack()
        })
    }
  }

  const requestChangeQuantityItem = async () => {
    try {
      const endpoint = `${HTTP_CONTANTS.updateQuantityItem}`
      const bodyData = {
        orderId: orderDetail.id,
        quantity: newEditQuantity,
        productId: idEditQuantity
      }
      //console.log('bodyData', bodyData)
      const data = await requestHttp('post', endpoint, bodyData)
      onCancelEditQuantity();
      requestOrderDetail()
      swal('Hecho!', 'Cambio de cantidad en el producto', 'success')
        .then(_ => {
          //redirectToBack()
        })
    } catch (error) {
      swal("Error", "Ocurrio un error actualizando la cantidad del producto", "warning")
        .then(_ => {
          //redirectToBack()
        })
    }
  }

  const redirectToBack = () => {
    history.goBack()
  }

  return (
    <>
      <main>
        <section className="page-content">
          <HeaderPage title="Detalle del pedido" icon="bonfire-sharp" />
        </section>
        {
          orderDetail.status !== -1
          && (
            <form onSubmit={onSubmitHandler}>
              <section className="row">
                <div className="col-md-6"></div>
                <div className="col-md-4">
                  <SelectField value={orderDetail.status} onChange={value => onChangeStatus(value)} itemList={ ORDER_STATUS } icon="flag-sharp" label="Estado de la orden" />
                </div>
                <div style={{ paddingTop: '17px' }} className="col-md-2">
                  <button type="submit" disabled={false} className="pure-button primary-btn">Cambiar</button>
                </div>
              </section>
            </form>
          )
        }

        <br />
        <section>
          <table className="table">
            <thead>
              <tr>
                <td>Producto</td>
                <td>Unidad/Caja</td>
                <td>Cantidad</td>
                <td>Valor</td>
                <td>Total</td>
              </tr>
            </thead>
            <tbody>
            {
              items.map((item, key) =>
                <tr key={key}>
                  <td>{item.product ? item.product.name : '' }</td>
                  <td>{ getBaleTypeById(item.unit_type)}</td>
                  <td>
                    {
                      modeEditQuantity && idEditQuantity === item.id
                      ? (
                          <>
                            <Field width={'40px'} value={newEditQuantity} type="number" onChange={onChangeQuantityItem} />
                            <button onClick={() => onSaveEditQuantity()} className="btn btn-link">Guardar</button>
                            <button onClick={() => onCancelEditQuantity()} className="btn btn-link">Cancelar</button>
                          </>
                        )
                      :
                        (
                          <>
                            <span>{item.quantity}</span>
                            <button onClick={() => onModeEditQuantity(item.id, item.quantity)} className="btn btn-link">Editar</button>
                          </>
                        )
                    }
                  </td>
                  <td>{item.price}</td>
                  <td><strong>{item.price * item.quantity}</strong></td>
                </tr>
              )
            }
            </tbody>
          </table>
        </section>
      </main>
    </>
  )
}
