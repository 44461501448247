import React from 'react';
import { Routes } from './pages/Routes';
import { MenuContextStore } from './contexts/MenuContext';
import { AccountContextStore } from './contexts/AccountContext';

export const App = () => (
  <AccountContextStore>
    <MenuContextStore>
      <Routes />
    </MenuContextStore>
  </AccountContextStore>
)
