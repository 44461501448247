import React, { useState, useEffect } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import Logo from './../../static/clippus_c.png'

export const Blocked = () => {

  const [showSubscription, setShowSubscription] = useState (false)
  let { path } = useRouteMatch()

  useEffect(() => {
    const trialExpired = path === '/trial-expired'
    setShowSubscription(trialExpired)

    return () => { }
  }, [])

  return (
    <section className="container">
      <div className="row">
        <section className="col-md-6 offset-md-3">
          <section className="discalimer-section">
            <div className="logo-disclaimer">
              <img alt="logo" src={Logo} width="140px" />
            </div>
            <h2>Tu cuenta se encuentra bloqueada</h2>
            <p>Comunícate con nuestro equipo de soporte al correo electrónico <a href="mailto:info@tappt.us">info@tappt.us</a> para verificar el estado de tu suscripción o de tu pago</p>
            <br />
            {
              showSubscription &&
              <Link to="/update-subscription">
                <div className="button-disclaimer">
                  <button type="button" className="pure-button primary-btn">Actualizar mi suscripción</button>
                </div>
              </Link>
            }
          </section>
        </section>
      </div>
    </section>
  )
}
