import React, { useState, useEffect } from 'react'
import { HeaderPage } from '../../components/HeaderPage'
import {
  Link
} from 'react-router-dom'
import Pagination from "react-js-pagination"
import { HTTP_CONTANTS } from '../../config/http-constants'
import { requestHttp } from '../../config/http-server'
import { Unauthorized } from '../../hooks/CheckAuthorization'
import { getRoleById } from '../../config/data-constants'

const initialPagination = {
  totalRows: 0
}

export const UserList = () => {

  const [activePage, setActivePage] = useState(1)
  const [users, setUsers] = useState([])
  const [pagination, setPagination] = useState(initialPagination)

  useEffect( () => {
    getUsers()

    return () => { }
  }, [activePage])

  const getUsers = async () => {
    try {
      const endpoint = HTTP_CONTANTS.users
      const data = await requestHttp('get', endpoint)
      setUsers(data.users)
      const pagination = {
        totalPages: data.totalPages,
        currentPage: data.currentPage,
        totalRows: data.totalRows,
        rowsPerPage: data.rowsPerPage
      }
      setPagination(pagination)
    } catch (err) {
      if (err.response.status === 401) {
        Unauthorized ()
      } else {
        console.error('error 500')
      }
    }
  }

  return (
    <>
      <main>
        <section className="page-content">
          <HeaderPage title="Usuarios" icon="bonfire-sharp" />
          <section className="visit-list-container">
            <div className="buttons-table">
              <div className="flex-divider"></div>
              <Link to={`/home/user/add`}>
                <button className="pure-button blue-btn">Agregar Usuario</button>
              </Link>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Código</th>
                    <th>Rol</th>
                    <th>Nombre</th>
                    <th>Contraseña</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    users.map((item, key) => (
                      <tr key={key} className={key % 2 === 0 ? 'pure-table-odd' : 'pure-table-pair'}>
                        <td>{ item.code }</td>
                        <td>{ getRoleById(item.role) }</td>
                        <td>{ item.name }</td>
                        <td>
                          <Link className="primary-link" to={`/home/user-password/${item.id}`}>
                            Cambiar contraseña
                          </Link>
                        </td>
                        <td>
                          <Link className="primary-link" to={`/home/user/${item.id}`}>
                            Editar Usuario
                          </Link>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            <div className="pagination-table">
              <div className="flex-divider"></div>
              <Pagination
                activePage={activePage}
                itemsCountPerPage={pagination.rowsPerPage}
                totalItemsCount={pagination.totalRows}
                pageRangeDisplayed={5}
                onChange={page => setActivePage(page)}
              />
            </div>
          </section>
        </section>
      </main>
    </>
  )
}
