import React from 'react'
import { Link } from 'react-router-dom'
import Logo from './../../static/clippus_c.png'

export const Disclaimer = () => {

  return (
    <section className="container">
      <div className="row">
        <section className="col-md-8 offset-md-2">
          <section className="discalimer-section">
            <div className="logo-disclaimer">
              <img alt="logo" src={Logo} width="140px" />
            </div>
            <h4>Hola,</h4>
            <p>Gracias por usar Clippus. A continuación te compartimos información que puede ser de tu interés:</p>
            <h3>Protección de datos</h3>
            <p>Los datos recolectados en los puntos de ingreso/salida son cobijados bajo nuestra <a target="_black" href="https://clippus.info/politica-tratamiento-datos.html">Política de Tratamiento de Datos Personales</a> y también la política de tu empresa. Debes tener cuidado de NO utilizar la información personal de los visitantes con fines comerciales, solo para fines abarcados dentro de tu propia política de protección de datos personales y para dar cumplimiento a normativas específicas. </p>
            <h3>Tiempo de Prueba</h3>
            <p>Puedes usar la aplicación para pruebas por 3 días. Luego te pediremos que completes un formulario de subscripción con una información básica y donde deberás compartirnos un enlace al documento de protección de datos o 'Habeas data' de tu empresa. Es posible que alguien de nuestro equipo se comunique contigo para confirmar o validar alguna información.</p>
            <h3>Forma de pago</h3>
            <p><strong>El cobro se realiza mes vencido con corte al día 1 de cada mes</strong>, por eso es importante que nos dejes tus datos para enviarte la factura de forma oportuna. Si tienes inquietudes sobre el cobro escríbenos al correo info@tappt.us</p>
            <h3>Descarga la app móvil</h3>
            <p>Recuerda que la app para realizar la lectura de cédulas de tus visitantes está disponible para dispositivos Android y puedes encontrarla en <a target="_blank" href="https://play.google.com/store/apps/details?id=com.virtualbeams.clippus">este enlace</a>.</p>
            <p><strong>¡Considera!</strong> El dispositivo debe contar con una camara de <strong>13 megapíxeles o superior.</strong></p>
            <br />
            <Link to="/create-point">
              <div className="button-disclaimer">
                <button type="button" className="pure-button primary-btn">Ingresar a la plataforma</button>
              </div>
            </Link>
            <br /><br />
          </section>
        </section>
      </div>
    </section>
  )
}
