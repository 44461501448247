import React, { createContext, useState, useEffect } from 'react'

const initialState = {}

export const AccountContext = createContext(initialState)

export const AccountContextStore = ({ children }) => {

  const [account, setAccount] = useState(initialState)

  return (
    <AccountContext.Provider value={{ account, setAccount }}>
      {children}
    </AccountContext.Provider>
  )
}
