import axios from 'axios'
import { HTTP_CONTANTS } from './http-constants';

const bearerToken = sessionStorage.getItem('__RabbitCookie__')

export default axios.create({
  baseURL: HTTP_CONTANTS.url,
  headers: {
    "Content-type": "application/json",
    'Authorization': `Bearer ${bearerToken}`
  }
});
