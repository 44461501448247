import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import BgImage from './../../static/bg-home.png'
import { Field } from '../../components/Field'
import Logo from './../../static/logo.png'
import { HTTP_CONTANTS } from '../../config/http-constants'
import swal from 'sweetalert'
import { disabledStyle, enabledStyle } from '../../styles/global'
import { requestHttp } from '../../config/http-server'
import { Authorized } from '../../hooks/CheckAuthorization'

export const Login = () => {

  const [validForm, setValidForm] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const history = useHistory()

  const onSubmitHandler = (e) => {
    e.preventDefault()
    const data = buildData()
    requestLogin(data)
  }

  useEffect(() => {
    const isValid = email && password
    setValidForm(isValid)

    return () => { }
  }, [email, password])

  const buildData = () => {
    return {
      email: email.trim(),
      password
    }
  }

  const requestLogin = async (credentials) => {
    try {
      const endpoint = HTTP_CONTANTS.login
      const data = await requestHttp('post', endpoint, credentials)
      if (data.role === 1) {
        Authorized(data.access_token)
        redirectToHome()
      } else {
        swal("Error", "No tiene autorización para ingresar a Clippus web", "warning")
      }
    } catch (error) {
      swal("Error", "Usuario y/o contraseña no son correctos", "error")
    }
  }

  const redirectToHome = () => {
    history.push('/home')
  }

  return (
    <section className="container">
      <div className="row">
        <section className="col-md-6 offset-md-3">
          <section className="login-section">
            <div className="logo-center">
              <img alt="logo" src={Logo} width="180px" />
            </div>
            <h1>¡Hola!</h1>
            <p>Panel de administración Wycom</p>
            <form onSubmit={ onSubmitHandler }>
              <section className="form-custom">
                <Field value={ email } onChange={ text => setEmail(text) } placeholder="ej: john@example.com" icon="mail" label="Ingresa tu correo electrónico" />
                <Field value={ password } onChange={ text => setPassword(text) } placeholder="Contraseña secreta" type="password" icon="shield-checkmark" label="Ingresa tu contraseña" />
                <br /><br />
                <div className="buttons-section">
                  <div>
                    <button disabled={!validForm} style={!validForm ? disabledStyle : enabledStyle} type="submit" className="pure-button primary-btn">Acceder ahora</button>
                  </div>
                </div>
              </section>
            </form>
            <br /><br />
          </section>
        </section>
      </div>
    </section>
  )
}
